import {
  ApplicationCancelError,
  ApplicationCancelToken,
  buildApplicationCancelTokenSource
} from '@frontapp/ui-bridge';
import cuid from 'cuid';
import {ConnectableObservable, fromEvent, MonoTypeOperatorFunction, Observable} from 'rxjs';
import {filter, map, publish, publishReplay, take} from 'rxjs/operators';

import {
  isContextUpdateMessageWithPort,
  isFunctionResultMessage,
  WebViewBaseBridge,
  WebViewBridge,
  webViewBridgeHandshake,
  WebViewHostMessageWithPort,
  WebViewMessageTypesEnum
} from './webViewSdkTypes';

type ContextFunctionCall = (
  port: MessagePort,
  contextId: string,
  callId: string,
  name: string,
  args: ReadonlyArray<unknown>
) => Observable<unknown>;
type ContextFunctionCancel = (port: MessagePort, callId: string) => void;

/*
 * Bridge.
 */

function buildWebViewBridge() {
  /*
   * Core observable.
   */

  // Create an observable for any hostmessage without ever unsubscribing.
  const messages = new Observable<WebViewHostMessageWithPort>(next => connectToParent().subscribe(next)).pipe(
    publish(),
    connectOnSubscribe()
  );

  const contextUpdates = messages.pipe(
    filter(isContextUpdateMessageWithPort),
    publishReplay(1),
    connectOnSubscribe()
  );

  /*
   * Context function forwarders.
   */

  const proxyFunctionCall: ContextFunctionCall = (port, callId, contextId, name, args) => {
    port.postMessage({
      type: WebViewMessageTypesEnum.FUNCTION_CALL,
      id: callId,
      contextId,
      name,
      args
    });

    return messages.pipe(
      filter(isFunctionResultMessage),
      filter(m => m.id === callId),
      map(m => {
        switch (m.type) {
          case WebViewMessageTypesEnum.FUNCTION_RESULT:
            return m.value;

          case WebViewMessageTypesEnum.FUNCTION_ERROR:
          default:
            throw new Error(String(m.error));
        }
      }),
      take(1)
    );
  };

  const proxyFunctionCancel: ContextFunctionCancel = (port, callId) => {
    port.postMessage({type: WebViewMessageTypesEnum.FUNCTION_CANCEL, id: callId});
  };

  /*
   * Context proxy.
   */

  const contextProxyUpdates = contextUpdates.pipe(
    map(
      ({port, context}) =>
        new Proxy(context, {
          get: (target, property) => {
            const propertyName = String(property);
            const functionArity = target.functionArities[propertyName];

            // If the property exists or it is not a function with arity, let the target handle it.
            if (property in target || !Number.isInteger(functionArity))
              return (target as any)[property];

            // Otherwise, create the function.
            return (...args: ReadonlyArray<any>) => {
              const callId = cuid();

              // Extract the cancel token.
              const cancelTokenIndex = functionArity - 1;
              const cancelToken: ApplicationCancelToken = args[cancelTokenIndex];

              // Do not send the cancel token in the proxied arguments.
              const argsToSend = args.slice(0, cancelTokenIndex);

              return new Promise((resolve, reject) => {
                const subscription = proxyFunctionCall(
                  port,
                  callId,
                  context.id,
                  propertyName,
                  argsToSend
                ).subscribe({
                  next: resolve,
                  error: reject
                });

                if (cancelToken)
                  cancelToken.promise.then(() => {
                    if (subscription.closed)
                      return;

                    subscription.unsubscribe();
                    reject(new ApplicationCancelError());

                    proxyFunctionCancel(port, callId);
                  });
              });
            };
          }
        })
    )
  );

  const baseBridge: WebViewBaseBridge = {
    contextUpdates: contextProxyUpdates,
    buildCancelTokenSource: buildApplicationCancelTokenSource,
    isCancelError: error => error instanceof ApplicationCancelError
  };

  /*
   * SDK proxy.
   */

  return (new Proxy(baseBridge, {
    get: (target, property) => {
      // If the property exists on the target, return it.
      if (property in target)
        return (target as any)[property];
      // Otherwise, assume that it's an async function call.
      return (...args: ReadonlyArray<any>) =>
        new Promise((resolve, reject) => {
          contextProxyUpdates.pipe(take(1)).subscribe({
            next: async (context: any) => {
              // Check if the property requested exists on the latest proxied context.
              const contextValue = context[property];

              if (contextValue === undefined) {
                const error = `${String(property)} is not a valid function for context ${context.type}.`;
                reject(new ReferenceError(error));
                return;
              }

              try {
                resolve(await contextValue(...args));
              } catch (error) {
                reject(error);
              }
            },
            error: reject
          });
        });
    }
  }) as unknown) as WebViewBridge;
}

/*
 * Helpers.
 */

function connectToParent() {
  const {parent} = window;
  const {port1, port2} = new MessageChannel();

  const messages = fromEvent<MessageEvent>(port1, 'message').pipe(
    map(
      e =>
        ({
          ...e.data,
          port: port1
        } as WebViewHostMessageWithPort)
    )
  );

  // Start the port and send the handshake.
  port1.start();
  parent.postMessage(webViewBridgeHandshake, '*', [port2]);

  return messages;
}

function connectOnSubscribe<T>(): MonoTypeOperatorFunction<T> {
  return source => {
    if (!isConnectableObservable(source))
      return source;

    return new Observable<T>(next => {
      const subscription = source.subscribe(next);
      source.connect();
      return subscription;
    });
  };
}

function isConnectableObservable<T>(source: Observable<T>): source is ConnectableObservable<T> {
  return typeof (source as any).connect === 'function';
}

/*
 * Exports
 */

export * from '@frontapp/ui-bridge';

const Front = buildWebViewBridge();
export const {buildCancelTokenSource, contextUpdates, isCancelError} = Front;

/** Delegate clicks on anchor elements targetting a new window to Front. */
export function delegateAnchorClicksToFront() {
  // Traps are not needed in a non-embedded context.
  if (window.parent === window.self)
    return;

  // Capture clicks.
  window.addEventListener('click', event => {
    // No need to do anything if the click did not use the default button.
    if (event.button !== 0)
      return;

    // Find the targetted anchor element.
    const {target} = event;
    const anchorElement = target instanceof window.HTMLElement ? target.closest('a') : null;

    // We can't do anything without an href
    if (!anchorElement || !anchorElement.href)
      return;

    // Don't do anything if the anchor is not opening a new window nor is a mailto link.
    if (anchorElement.target !== '_blank' && !anchorElement.href.startsWith('mailto:'))
      return;

    // Parse the href to support relative paths.
    const url = new URL(anchorElement.href, window.location.href);

    event.preventDefault();
    Front.openUrl(url.href);
  });
}

// eslint-disable-next-line import/no-default-export
export default Front;
